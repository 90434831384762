import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  IconWithMargin,
  StyledTertiaryButton,
} from 'components/SwitchPaymentButton/styles';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { trackButtonClick } from 'helpers/eventTracking';
import replaceMtaQuotePlaceholders from 'helpers/replaceMtaQuotePlaceholders';
import { MtaQuote } from 'state/mta/mtaQuote';
import { CsIcon } from 'types/contentStack';

type MtaSwitchToSinglePaymentButtonProps = {
  quote: MtaQuote;
  onClick: React.MouseEventHandler;
} & ComponentProps;

const query = graphql`
  query {
    csPayment {
      payment_summary {
        switch_payment_buttons {
          switch_to_annual
          switch_icon {
            icon_code
          }
        }
      }
    }
  }
`;

export type MtaSwitchToSinglePaymentButtonData = {
  csPayment: {
    payment_summary: {
      switch_payment_buttons: {
        switch_to_annual: string;
        switch_icon: [CsIcon];
      };
    };
  };
};

const MtaSwitchToSinglePaymentButton: React.FC<MtaSwitchToSinglePaymentButtonProps> = ({
  quote,
  onClick,
  ...props
}) => {
  const data = useStaticQuery<MtaSwitchToSinglePaymentButtonData>(query);

  const { switch_payment_buttons } = replaceMtaQuotePlaceholders<
    MtaSwitchToSinglePaymentButtonData
  >(data, quote).csPayment.payment_summary;

  const switchIcon = unwrapSingleton(switch_payment_buttons.switch_icon);
  const switchText = switch_payment_buttons.switch_to_annual;

  return (
    <StyledTertiaryButton
      onClick={(event) => {
        trackButtonClick('mtaSwitchToSinglePayment', switchText);
        onClick(event);
      }}
      {...componentProps(props)}>
      {switchIcon && (
        <IconWithMargin name={switchIcon.icon_code} size="medium" displayVariant />
      )}
      {switchText}
    </StyledTertiaryButton>
  );
};

export default MtaSwitchToSinglePaymentButton;
