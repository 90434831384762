import { WarningPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const InfoBannerHeading = styled.h2`
  ${fonts.headingSmall}
  margin: 0;
  padding-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    padding-bottom: ${spacing(2)};
  `}
`;

export const ContentWrapper = styled.div`
  margin-bottom: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin: 0 ${spacing(1)} ${spacing(1)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: 0;
  `}
`;

export const StyledImage = styled.img`
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin: 0;
    position: absolute;
    top: ${spacing(3)};
    right: ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    top: ${spacing(4)};
    right: ${spacing(4)};
  `}
`;

export const StyledWarningPanel = styled(WarningPanel)`
  padding: ${spacing(4)} ${spacing(3)};
  margin-top: ${spacing(3)};
`;
