import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import MaestroLogo from 'assets/maestro.svg';
import MastercardLogo from 'assets/mastercard.svg';
import VisaLogo from 'assets/visa.svg';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import CardPayment, {
  CardHolderDetails,
  CardPaymentValidity,
} from 'components/CardPayment';
import useCardPaymentSectionContent from 'components/Payment/CardPaymentSection/content';
import { PaysafeInstance } from 'components/Paysafe';
import replaceMtaQuotePlaceholders from 'helpers/replaceMtaQuotePlaceholders';
import { MtaQuote } from 'state/mta/mtaQuote';
import SavedCreditCardInfo from './SavedCreditCardInfo';
import {
  Heading,
  HeadingAndLogoWrapper,
  HeadingWrapper,
  LogoContainer,
  LogoWrapper,
  RichTextWithMargin,
} from './styles';

type MtaCardPaymentSectionContent = {
  csMtaPayment: {
    card_details: {
      heading: string;
      description: {
        annual_decrease: string;
        annual_increase: string;
      };
    };
  };
};

const query = graphql`
  query {
    csMtaPayment {
      card_details {
        heading
        description {
          annual_decrease
          annual_increase
        }
      }
    }
  }
`;

type MtaCardPaymentSectionProps = {
  mtaQuote: MtaQuote;
  details: CardHolderDetails;
  updateDetails: (update: Partial<CardHolderDetails>) => void;
  setPaysafeInstance: (instance: PaysafeInstance) => void;
  updateValidity: (update: Partial<CardPaymentValidity>) => void;
  getError: FieldFunction<CardPaymentValidity, string | undefined>;
  showValidation: FieldFunction<CardPaymentValidity, void>;
} & ComponentProps;

const CardPaymentForm: React.FC<MtaCardPaymentSectionProps> = ({
  mtaQuote,
  details,
  updateDetails,
  setPaysafeInstance,
  updateValidity,
  getError,
  showValidation,
}) => {
  const questionContent = useCardPaymentSectionContent();
  const { questions } = replaceMtaQuotePlaceholders(questionContent, mtaQuote);

  return (
    <GridItem>
      <CardPayment
        updateValidity={updateValidity}
        details={details}
        updateDetails={updateDetails}
        setPaysafeInstance={setPaysafeInstance}
        questions={questions}
        getError={getError}
        showValidation={showValidation}
      />
    </GridItem>
  );
};

const MtaCardPaymentSection: React.FC<MtaCardPaymentSectionProps> = ({
  mtaQuote,
  details,
  updateDetails,
  setPaysafeInstance,
  updateValidity,
  getError,
  showValidation,
  ...rest
}) => {
  const headingContent = useStaticQuery<MtaCardPaymentSectionContent>(query);
  const headings = replaceMtaQuotePlaceholders(headingContent, mtaQuote).csMtaPayment
    .card_details;

  const isPaymentIncrease = mtaQuote.mtaPaymentAmount > 0;
  return (
    <Grid
      {...componentProps(rest)}
      alignLeft
      as="section"
      id="card-payment-section"
      aria-labelledby="card-payment-heading">
      <GridItem desktop={10} tabletLandscape={10}>
        <HeadingAndLogoWrapper>
          <HeadingWrapper>
            <Heading id="card-payment-heading">{headings.heading}</Heading>
            <RichTextWithMargin
              html={
                isPaymentIncrease
                  ? headings.description.annual_increase
                  : headings.description.annual_decrease
              }
            />
          </HeadingWrapper>
          <LogoWrapper>
            <LogoContainer>
              <img src={MastercardLogo} alt="Mastercard Logo" />
            </LogoContainer>
            <LogoContainer>
              <img src={VisaLogo} alt="Visa Logo" />
            </LogoContainer>
            <LogoContainer>
              <img src={MaestroLogo} alt="Maestro Logo" />
            </LogoContainer>
          </LogoWrapper>
        </HeadingAndLogoWrapper>
      </GridItem>
      {isPaymentIncrease ? (
        <CardPaymentForm
          mtaQuote={mtaQuote}
          updateValidity={updateValidity}
          details={details}
          updateDetails={updateDetails}
          setPaysafeInstance={setPaysafeInstance}
          getError={getError}
          showValidation={showValidation}
        />
      ) : (
        <SavedCreditCardInfo
          storedCreditCardDetails={mtaQuote.storedPaymentDetails.creditCardDetails}
        />
      )}
    </Grid>
  );
};

export default MtaCardPaymentSection;
