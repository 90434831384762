import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import SavedDirectDebitInfo from 'components/Payment/SavedDirectDebitInfoSection/SavedDirectDebitInfo';
import replaceQuotePlaceholders from 'helpers/replaceQuotePlaceholders';
import { MtaQuote } from 'state/mta/mtaQuote';
import {
  Heading,
  StyledPaymentPlanLinkAndModal,
  StyledRichTextWithModal,
  Subheading,
} from './styles';

type MtaDirectDebitSectionContent = {
  csMtaPayment: {
    monthly_payment_plan_section: {
      description: string;
      heading: {
        payment_increase: string;
        payment_decrease: string;
      };
    };
    direct_debit_info: {
      heading: string;
      description: {
        no_refund_required: string;
        refund_required: string;
      };
    };
  };
};

const query = graphql`
  query {
    csMtaPayment {
      monthly_payment_plan_section {
        description
        heading {
          payment_increase
          payment_decrease
        }
      }
      direct_debit_info {
        heading
        description {
          no_refund_required
          refund_required
        }
      }
    }
  }
`;

type MtaDirectDebitSectionProps = {
  mtaQuote: MtaQuote;
};

const MtaDirectDebitSection: React.FC<MtaDirectDebitSectionProps> = ({ mtaQuote }) => {
  const data = useStaticQuery<MtaDirectDebitSectionContent>(query);
  const { csMtaPayment } = replaceQuotePlaceholders(data, mtaQuote);

  const isPaymentIncrease = mtaQuote.mtaPaymentAmount > 0;

  const sectionSummary = csMtaPayment.direct_debit_info;
  const paymentPlanSection = csMtaPayment.monthly_payment_plan_section;

  const existingDirectDebitDetails = mtaQuote.storedPaymentDetails.directDebitDetails;

  // This should always exist for a monthly quote, there's nothing sensible we can do if this is null
  if (!existingDirectDebitDetails) {
    return null;
  }

  return (
    <Grid
      alignLeft
      as="section"
      id="direct-debit-section"
      aria-labelledby="direct-debit-heading">
      <GridItem desktop={10} tabletLandscape={10}>
        <Heading id="direct-debit-heading">{sectionSummary.heading}</Heading>
        <StyledRichTextWithModal
          html={
            isPaymentIncrease
              ? sectionSummary.description.no_refund_required
              : sectionSummary.description.refund_required
          }
        />
      </GridItem>
      <GridItem desktop={10} tabletLandscape={10}>
        <SavedDirectDebitInfo storedDirectDebitDetails={existingDirectDebitDetails} />
      </GridItem>
      <GridItem desktop={8} tabletLandscape={8}>
        <Subheading>
          {isPaymentIncrease
            ? paymentPlanSection.heading.payment_increase
            : paymentPlanSection.heading.payment_decrease}
        </Subheading>
        <StyledRichTextWithModal html={paymentPlanSection.description} />
        <StyledPaymentPlanLinkAndModal
          id="paymentPlanLink"
          quote={mtaQuote}
          paymentDate={existingDirectDebitDetails.preferredDayOfPayment}
        />
      </GridItem>
    </Grid>
  );
};

export default MtaDirectDebitSection;
