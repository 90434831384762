import { FullWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { ErrorPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import AutoRenewalOptInSection from 'components/Payment/AutoRenewalConfirmationSection/AutoRenewalOptInSection';
import { StyledPredefinedPanel } from 'components/Payment/AutoRenewalConfirmationSection/styles';
import RichTextWithModal from 'components/RichTextWithModal';

export const PaymentSummaryDivider = styled(FullWidthDivider)`
  margin-bottom: ${spacing(4)};
`;

export const ErrorPanelWithMargin = styled(ErrorPanel)`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(6)};
  `}
`;

export const RichTextWithMargin = styled(RichTextWithModal)`
  margin: 0 ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin: 0 ${spacing(5)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: 0;
  `}
`;

export const StyledAutoRenewalOptInSection = styled(AutoRenewalOptInSection)`
  & ${StyledPredefinedPanel} {
    margin-top: 0px;
  }
`;
