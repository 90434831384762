import { graphql, useStaticQuery } from 'gatsby';
import { CsTable } from 'types/contentStack';

export type MtaPaymentSummaryContent = {
  monthlyContent: {
    acceptTermsAndConditions: {
      acknowledgement: string;
      information: string;
      creditAgreementLink: string;
    };
    switchToAnnualText: string;
  };
  monthlyTableIncrease: CsTable;
  monthlyTableDecrease: CsTable;
};

type csMtaPaymentSummaryContent = {
  csPayment: {
    payment_summary: {
      accept_terms_and_conditions: {
        credit_agreement_link: string;
      };
      switch_payment_buttons: {
        switch_to_annual: string;
      };
    };
  };
  csMtaMonthlyPaymentTable: {
    table_mta_payment_decrease: CsTable;
    table_mta_payment_increase: CsTable;
  };
  csFcaMonthlyPayment: {
    accept_terms_and_conditions: {
      acknowledgement: string;
    };
  };
  csFcaMtaPayment: {
    payment_summary: {
      accept_terms_and_conditions: {
        information: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPayment {
      payment_summary {
        accept_terms_and_conditions {
          credit_agreement_link
        }
        switch_payment_buttons {
          switch_to_annual
        }
      }
    }
    csMtaMonthlyPaymentTable {
      table_mta_payment_decrease {
        header_row {
          has_empty_corner_cell
          corner_cell_column_span
          header_cells {
            heading_text
            column_span
            is_highlighted
          }
        }
        content_rows {
          content_row {
            cells {
              cell {
                content_rich_text
                column_span
                is_header_cell
                is_highlighted
              }
            }
          }
        }
      }
      table_mta_payment_increase {
        header_row {
          has_empty_corner_cell
          corner_cell_column_span
          header_cells {
            heading_text
            column_span
            is_highlighted
          }
        }
        content_rows {
          content_row {
            cells {
              cell {
                content_rich_text
                column_span
                is_header_cell
                is_highlighted
              }
            }
          }
        }
      }
    }
    csFcaMonthlyPayment {
      accept_terms_and_conditions {
        acknowledgement
      }
    }
    csFcaMtaPayment {
      payment_summary {
        accept_terms_and_conditions {
          information
        }
      }
    }
  }
`;

const useMtaPaymentSummaryContent = (): MtaPaymentSummaryContent => {
  const csContent = useStaticQuery<csMtaPaymentSummaryContent>(query);

  return {
    monthlyContent: {
      acceptTermsAndConditions: {
        acknowledgement:
          csContent.csFcaMonthlyPayment.accept_terms_and_conditions.acknowledgement,
        information:
          csContent.csFcaMtaPayment.payment_summary.accept_terms_and_conditions
            .information,
        creditAgreementLink:
          csContent.csPayment.payment_summary.accept_terms_and_conditions
            .credit_agreement_link,
      },
      switchToAnnualText:
        csContent.csPayment.payment_summary.switch_payment_buttons.switch_to_annual,
    },
    monthlyTableIncrease: csContent.csMtaMonthlyPaymentTable.table_mta_payment_increase,
    monthlyTableDecrease: csContent.csMtaMonthlyPaymentTable.table_mta_payment_decrease,
  };
};

export default useMtaPaymentSummaryContent;
