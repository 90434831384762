import InfoBanner from '@rsa-digital/evo-shared-components/components/InfoBanner';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { isAnnualPayment } from 'api/businessLogic/quote';
import DirectDebitLogo from 'assets/directdebit.svg';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import replaceMtaQuotePlaceholders from 'helpers/replaceMtaQuotePlaceholders';
import { MtaQuote } from 'state/mta/mtaQuote';
import {
  ContentWrapper,
  InfoBannerHeading,
  StyledImage,
  StyledWarningPanel,
} from './styles';

type csMtaPaymentInfoBannerContent = {
  csMtaPayment: {
    info_banner: {
      annual_payment_increase: {
        heading: string;
        body: string;
      };
    };
  };
  csFcaMtaPayment: {
    info_banner: {
      new_card_with_cat: {
        heading: string;
        body: string;
      };
    };
  };
  csPayment: {
    info_banner: {
      monthly: {
        body: string;
        heading: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPayment {
      info_banner {
        monthly {
          body
          heading
        }
      }
    }
    csMtaPayment {
      info_banner {
        annual_payment_increase {
          heading
          body
        }
      }
    }
    csFcaMtaPayment {
      info_banner {
        new_card_with_cat {
          heading
          body
        }
      }
    }
  }
`;

type MtaPaymentInfoBannerProps = {
  mtaQuote: MtaQuote;
  id: string;
} & ComponentProps;

const MtaPaymentInfoBanner: React.FC<MtaPaymentInfoBannerProps> = ({
  mtaQuote,
  id,
  ...props
}) => {
  const content = useStaticQuery<csMtaPaymentInfoBannerContent>(query);
  const { csMtaPayment, csPayment, csFcaMtaPayment } = replaceMtaQuotePlaceholders(
    content,
    mtaQuote
  );

  const isAnnual = isAnnualPayment(mtaQuote);
  const showCatWarning = isAnnual && mtaQuote.paymentDetails.isCat;
  const isAnnualNonCat = isAnnual && !mtaQuote.paymentDetails.isCat;

  if (isAnnualNonCat) {
    return null;
  }

  const infoBanner = isAnnual
    ? csMtaPayment.info_banner.annual_payment_increase
    : csPayment.info_banner.monthly;

  return showCatWarning ? (
    <StyledWarningPanel {...componentProps(props)} id={id}>
      <ContentWrapper>
        <InfoBannerHeading>
          {csFcaMtaPayment.info_banner.new_card_with_cat.heading || ''}
        </InfoBannerHeading>
        <RichTextWithModal
          html={csFcaMtaPayment.info_banner.new_card_with_cat.body || ''}
        />
      </ContentWrapper>
    </StyledWarningPanel>
  ) : (
    <InfoBanner {...componentProps(props)} id={id} alignLeft>
      {!isAnnual && <StyledImage src={DirectDebitLogo} alt="Direct debit logo" />}
      <ContentWrapper>
        <InfoBannerHeading>{infoBanner.heading || ''}</InfoBannerHeading>
        <RichTextWithModal html={infoBanner.body || ''} />
      </ContentWrapper>
    </InfoBanner>
  );
};

export default MtaPaymentInfoBanner;
