import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import MtaSwitchToSinglePaymentButton from './MtaSwitchToSinglePaymentButton';

export const Wrapper = styled(PageWidthContainer)`
  background-color: ${colors.neutral07};
`;

export const StyledSwitchPaymentButton = styled(MtaSwitchToSinglePaymentButton)`
  margin-top: ${spacing(4)};
`;

export const SwitchPaymentWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(6)};
  `}
`;
