import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import {
  scrollToElement,
  scrollToPosition,
} from '@rsa-digital/evo-shared-components/helpers/scroll';
import { isMtaPaymentRefund, isMtaSinglePaymentOnly } from 'api/businessLogic/mtaPayment';
import { isAnnualPayment } from 'api/businessLogic/quote';
import mtaClient from 'api/mtaClient';
import paymentClient from 'api/paymentClient';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import LoadMtaQuoteWrapper from 'components/ApiRequestWrapper/LoadMtaQuoteWrapper';
import { CardPaymentDetails, initialCardPaymentValidity } from 'components/CardPayment';
import SecurePaymentFooterBlock from 'components/Footer/SecurePaymentFooter';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import MtaCardPaymentSection from 'components/MtaPayment/MtaCardPaymentSection';
import MtaDirectDebitSection from 'components/MtaPayment/MtaDirectDebitSection';
import MtaPaymentInfoBanner from 'components/MtaPayment/MtaPaymentInfoBanner';
import MtaPaymentSummarySection from 'components/MtaPayment/MtaPaymentSummarySection';
import { AutoRenewalOptInDetails } from 'components/Payment/AutoRenewalConfirmationSection/AutoRenewalOptInSection';
import useAutoRenewalOptInConfirmationSectionRules from 'components/Payment/AutoRenewalConfirmationSection/AutoRenewalOptInSection/validation';
import useCardPaymentSectionRules from 'components/Payment/CardPaymentSection/validation';
import useTermsAndConditionsRules, {
  TermsAndConditionsDetails,
} from 'components/Payment/PaymentSummarySection/validation';
import { PaysafeInstance } from 'components/Paysafe';
import { getPaysafeTokenWithTracking } from 'components/Paysafe/getPaysafeToken';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import { trackPurchaseEvent, useCheckoutTracking } from 'helpers/ecommerceTracking';
import { trackAPIError, trackButtonClick, trackFieldError } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import logApiError from 'helpers/logApiError';
import replaceMtaQuotePlaceholders from 'helpers/replaceMtaQuotePlaceholders';
import { mtaRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { MtaQuote, useMtaQuote } from 'state/mta/mtaQuote';
import { isTieredQuote } from 'state/quote/quote';
import { useUser } from 'state/user/state';
import { CsHero, CsMeta } from 'types/contentStack';
import {
  ErrorPanelWithMargin,
  PaymentSummaryDivider,
  RichTextWithMargin,
  StyledAutoRenewalOptInSection,
} from './styles';

type MtaPaymentContent = {
  meta: CsMeta;
  hero_annual_increase: CsHero;
  hero_monthly_decrease: CsHero;
  hero_monthly_increase: CsHero;
  hero_refund_required: CsHero;
  next_button_text: string;
  payment_failed_rich_text: string;
};

type MtaPaymentProps = {
  data: {
    csMtaPayment: MtaPaymentContent;
  };
};

export const query = graphql`
  query {
    csMtaPayment {
      meta {
        meta_title
      }
      hero_annual_increase {
        subheading
        heading
      }
      hero_monthly_decrease {
        subheading
        heading
      }
      hero_monthly_increase {
        subheading
        heading
      }
      hero_refund_required {
        subheading
        heading
      }
      payment_failed_rich_text
      next_button_text
    }
  }
`;

const getHero = (
  content: MtaPaymentContent,
  isSinglePayment: boolean,
  mtaQuote: MtaQuote | null
): CsHero | undefined => {
  switch (mtaQuote?.policy.paymentOption) {
    case 'Annually':
      return mtaQuote.mtaPaymentAmount > 0
        ? content.hero_annual_increase
        : content.hero_refund_required;
    case 'Monthly':
      if (mtaQuote.mtaPaymentAmount > 0) {
        return isSinglePayment || isMtaSinglePaymentOnly(mtaQuote)
          ? content.hero_annual_increase
          : content.hero_monthly_increase;
      }
      if (isMtaPaymentRefund(mtaQuote)) {
        return content.hero_refund_required;
      }
      return content.hero_monthly_decrease;

    default:
      return undefined;
  }
};

type MtaPaymentState = CardPaymentDetails &
  TermsAndConditionsDetails &
  AutoRenewalOptInDetails;

const MtaPayment: React.FC<MtaPaymentProps & PolicyNumberProps> = ({
  data: { csMtaPayment },
  policyNumber,
}) => {
  const mtaQuote = useMtaQuote();
  const { isLoading, loadingMessage, requestHandler } = useApiRequestHandler();
  const [singlePaymentSelected, setSinglePaymentSelected] = useState<boolean>(false);

  useEffect(() => {
    scrollToPosition(0);
  }, [singlePaymentSelected]);

  const [paymentState, setPaymentState] = useState<MtaPaymentState>({
    cardHolder: '',
    ...initialCardPaymentValidity,
    hasAgreedToTermsAndConditions: undefined,
    hasAgreedToAutoRenewal: undefined,
  });

  const updatePaymentState = useCallback(
    (update: Partial<MtaPaymentState>) =>
      setPaymentState((state) => ({
        ...state,
        ...update,
      })),
    [setPaymentState]
  );

  const [paysafeInstance, setPaysafeInstance] = useState<PaysafeInstance>();
  const [paymentError, setPaymentError] = useState<boolean | undefined>(undefined);

  const isPaymentIncrease = mtaQuote && mtaQuote.mtaPaymentAmount > 0;
  const isSinglePayment =
    (mtaQuote && isMtaSinglePaymentOnly(mtaQuote)) || singlePaymentSelected;
  const isMonthlyInstallmentChange =
    !!mtaQuote && !isSinglePayment && !isMtaPaymentRefund(mtaQuote);

  const showMonthlyAutoRenewalQuestion =
    mtaQuote &&
    isTieredQuote(mtaQuote) &&
    !isSinglePayment &&
    isPaymentIncrease &&
    mtaQuote.paymentDetails.isAutoRenewal === false;

  const cardPaymentRules = useCardPaymentSectionRules();
  const termsAndConditionsRules = useTermsAndConditionsRules();
  const autoRenewalRules = useAutoRenewalOptInConfirmationSectionRules();

  const { getError, validateOnSubmit, showValidation } = useValidation(
    paymentState,
    {
      ...(!isSinglePayment ? termsAndConditionsRules : {}),
      ...(isPaymentIncrease && isSinglePayment ? cardPaymentRules : {}),
      ...(showMonthlyAutoRenewalQuestion ? autoRenewalRules : {}),
    },
    trackFieldError
  );

  const processedContent = mtaQuote
    ? replaceMtaQuotePlaceholders(csMtaPayment, mtaQuote)
    : csMtaPayment;
  const { meta, payment_failed_rich_text } = processedContent;

  const hero = getHero(processedContent, singlePaymentSelected, mtaQuote);

  const completePayment = async (quoteForPayment: MtaQuote): Promise<void> => {
    if (!isPaymentIncrease) {
      await mtaClient.confirm(quoteForPayment.policyNumber);
    } else if (isSinglePayment) {
      const paysafeToken = await getPaysafeTokenWithTracking(
        paysafeInstance,
        quoteForPayment.mtaPaymentAmount,
        paymentState.cardHolder,
        quoteForPayment.account.address,
        !singlePaymentSelected,
        false
      );
      await paymentClient.mtaPayByCard(quoteForPayment.policyNumber, paysafeToken);
    } else {
      await paymentClient.mtaPayByDirectDebit(
        quoteForPayment.policyNumber,
        paymentState.hasAgreedToAutoRenewal
      );
    }
  };

  const completePaymentAndMoveNext = async (quoteForPayment: MtaQuote): Promise<void> => {
    await requestHandler(async () => {
      await completePayment(quoteForPayment)
        .then(() => {
          trackPurchaseEvent(
            quoteForPayment,
            policyNumber,
            'MTA',
            isMonthlyInstallmentChange
          );
          navigate(mtaRoutes.confirmation(policyNumber));
        })
        .catch((error) => {
          logApiError(error);
          setPaymentError(true);
          scrollToElement('paymentError', 20);
          if (isAxiosError(error)) {
            trackAPIError(error.response.status ?? 400, 'paymentError');
          }
        });
    }, 'Paying for quote, please wait');
  };

  useCheckoutTracking(3, mtaQuote, 'MTA');
  const [user] = useUser();
  usePageTracking(meta.meta_title, !!user?.isLoggedIn && !!mtaQuote);

  return (
    <>
      <Layout meta={meta} pageType="mtaAndRenewal">
        <LoadMtaQuoteWrapper policyNumber={policyNumber}>
          {isLoading && <LoadingOverlay loadingMessage={loadingMessage || ''} />}
          {mtaQuote && (
            <form
              onSubmit={validateOnSubmit(
                () => completePaymentAndMoveNext(mtaQuote),
                scrollAndTrackError
              )}>
              <HeroPlain heading={hero?.heading || ''} subhead={hero?.subheading || ''} />
              {isMonthlyInstallmentChange && (
                <>
                  <MtaPaymentSummarySection
                    mtaQuote={mtaQuote}
                    hasAgreed={paymentState.hasAgreedToTermsAndConditions}
                    updateHasAgreed={(update) => {
                      updatePaymentState({ hasAgreedToTermsAndConditions: update });
                      showValidation('hasAgreedToTermsAndConditions');
                    }}
                    getError={getError}
                    switchToSinglePayment={() => {
                      setPaymentError(undefined);
                      setSinglePaymentSelected(true);
                    }}
                  />
                  <PaymentSummaryDivider />
                </>
              )}
              {paymentError && (
                <ErrorPanelWithMargin id="paymentError">
                  <RichTextWithMargin
                    html={payment_failed_rich_text}
                    onLinkClick={(label) =>
                      trackButtonClick('paymentErrorRichTextLink', label)
                    }
                  />
                </ErrorPanelWithMargin>
              )}
              {!isMonthlyInstallmentChange && (
                <MtaCardPaymentSection
                  mtaQuote={mtaQuote}
                  details={paymentState}
                  updateDetails={updatePaymentState}
                  setPaysafeInstance={setPaysafeInstance}
                  updateValidity={updatePaymentState}
                  getError={getError}
                  showValidation={showValidation}
                />
              )}
              {isMonthlyInstallmentChange && (
                <MtaDirectDebitSection mtaQuote={mtaQuote} />
              )}
              {((isAnnualPayment(mtaQuote) && isPaymentIncrease) ||
                isMonthlyInstallmentChange) && (
                <MtaPaymentInfoBanner
                  mtaQuote={mtaQuote}
                  id="payment-information-banner"
                />
              )}
              {showMonthlyAutoRenewalQuestion && (
                <StyledAutoRenewalOptInSection
                  hasAgreedToAutoRenewal={paymentState.hasAgreedToAutoRenewal}
                  updateHasAgreed={(update) => {
                    updatePaymentState({ hasAgreedToAutoRenewal: update });
                    showValidation('hasAgreedToAutoRenewal');
                  }}
                  getError={getError}
                />
              )}
              <FormFooter
                contentColumns={{ desktop: 11 }}
                moveNextButton={{
                  text: processedContent.next_button_text,
                }}
                backButton={{
                  onClick: () => navigate(mtaRoutes.checkYourDetails(policyNumber)),
                }}
              />
            </form>
          )}
        </LoadMtaQuoteWrapper>
      </Layout>
      <SecurePaymentFooterBlock />
    </>
  );
};

export default withPolicyNumber(MtaPayment);
