import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import PaymentPlanLinkAndModal from 'components/Payment/PaymentPlanLinkAndModal';
import RichTextWithModal from 'components/RichTextWithModal';

export const Heading = styled.h2`
  ${fonts.headingMedium}
  margin: 0 0 ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(1)};
  `}
`;

export const SubheadingLogoWrapper = styled.div`
  display: flex;
  align-items: column;
  flex-wrap: wrap;

  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const Subheading = styled.h3`
  ${fonts.headingSmall};

  margin: ${spacing(3)} 0 ${spacing(1.5)};

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(6)} 0 ${spacing(2)};
  `}
`;

export const StyledPaymentPlanLinkAndModal = styled(PaymentPlanLinkAndModal)`
  margin: ${spacing(2)} 0;

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(1)};
  `}
`;

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}

    ${mediaQuery.tabletLandscape`
      ${fonts.paragraph}
    `}
  }
`;
