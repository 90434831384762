import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const Heading = styled.h2`
  ${fonts.headingMedium}
  margin: 0 0 ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(1)};
  `}
`;

export const RichTextWithMargin = styled(RichTextWithModal)`
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(1)};
  `}
`;

export const HeadingWrapper = styled.div`
  margin-right: auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 36px;

  ${mediaQuery.tabletPortrait`
    width: 76px;
    height: 64px;
  `}

  img {
    width: 100%;
    height: 100%;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing(1)};

  ${LogoContainer}:not(:first-child) {
    margin-left: ${spacing(1)};
  }
`;

export const HeadingAndLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
