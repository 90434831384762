import { TableRow } from '@rsa-digital/evo-shared-components/components/ExpandableTable';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { StoredCreditCardDetails } from 'state/quote/storedPaymentDetails';
import { StyledExpandableTable } from './styles';

type SavedCreditCardInfoContent = {
  csPayment: {
    stored_details_section: {
      card_number_label: string;
      expiry_date_label: string;
      security_code_label: string;
      security_code_masking_text: string;
    };
  };
};

const query = graphql`
  query {
    csPayment {
      stored_details_section {
        card_number_label
        expiry_date_label
        security_code_label
        security_code_masking_text
      }
    }
  }
`;

type SavedCreditCardInfoProps = {
  storedCreditCardDetails: StoredCreditCardDetails;
};

const SavedCreditCardInfo: React.FC<SavedCreditCardInfoProps> = ({
  storedCreditCardDetails,
}) => {
  const { csPayment } = useStaticQuery<SavedCreditCardInfoContent>(query);
  const storedDetailsSection = csPayment.stored_details_section;
  const creditCardDataRows: TableRow[] = [
    {
      title: storedDetailsSection.card_number_label,
      value: storedCreditCardDetails.redactedCardNumber,
    },
    {
      title: storedDetailsSection.expiry_date_label,
      value: storedCreditCardDetails.expirationDate,
    },
    {
      title: storedDetailsSection.security_code_label,
      value: storedDetailsSection.security_code_masking_text,
    },
  ];

  return (
    <GridItem desktop={10} tabletLandscape={10} tabletPortrait={6}>
      <StyledExpandableTable
        initialRows={creditCardDataRows}
        collapsibleRows={[]}
        expanded={false}
        data-pii-mask
      />
    </GridItem>
  );
};

export default SavedCreditCardInfo;
