import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React from 'react';
import { MonthlyPaymentSummaryTable } from 'components/Payment/PaymentSummarySection/PaymentSummaryTable';
import { TermsAndConditionsDetails } from 'components/Payment/PaymentSummarySection/validation';
import { trackButtonClick } from 'helpers/eventTracking';
import replaceMtaQuotePlaceholders from 'helpers/replaceMtaQuotePlaceholders';
import { MtaQuote } from 'state/mta/mtaQuote';
import useMtaPaymentSummaryContent, { MtaPaymentSummaryContent } from './content';
import { StyledSwitchPaymentButton, SwitchPaymentWrapper, Wrapper } from './styles';

type MtaPaymentSummarySectionProps = {
  mtaQuote: MtaQuote;
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  getError: FieldFunction<TermsAndConditionsDetails, string | undefined>;
  switchToSinglePayment: React.MouseEventHandler;
};

const MtaPaymentSummarySection: React.FC<MtaPaymentSummarySectionProps> = ({
  mtaQuote,
  hasAgreed,
  updateHasAgreed,
  getError,
  switchToSinglePayment,
}) => {
  const data = useMtaPaymentSummaryContent();
  const sectionContent = replaceMtaQuotePlaceholders<MtaPaymentSummaryContent>(
    data,
    mtaQuote
  );

  return (
    <Wrapper>
      <Grid as="section" id="payment-summary">
        <GridItem desktop={10} tabletLandscape={10}>
          <MonthlyPaymentSummaryTable
            quote={mtaQuote}
            sectionContent={{
              ...sectionContent,
              monthlyTable:
                mtaQuote.mtaPaymentAmount > 0
                  ? sectionContent.monthlyTableIncrease
                  : sectionContent.monthlyTableDecrease,
            }}
            hasAgreed={hasAgreed}
            updateHasAgreed={updateHasAgreed}
            getError={getError}
          />
          <SwitchPaymentWrapper>
            {mtaQuote.policy.paymentOption === 'Monthly' &&
              mtaQuote.mtaPaymentAmount > 0 && (
                <StyledSwitchPaymentButton
                  quote={mtaQuote}
                  onClick={(e) => {
                    trackButtonClick(
                      'switchPayment',
                      sectionContent.monthlyContent.switchToAnnualText
                    );
                    switchToSinglePayment(e);
                  }}
                />
              )}
          </SwitchPaymentWrapper>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};

export default MtaPaymentSummarySection;
